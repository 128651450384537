<template>
  <!-- begin #footer -->
  <div id="footer" class="footerz">
    <div class="container">
      <p>
        &copy; {{ app }} {{ year }}. All Rights Reserved
      </p>
      <p class="social-list">
        <a class="text-primary" href="#"><i class="fa fa-envelope"></i> tradefinance@cooperative.com </a>
        <a href="#">&nbsp;&nbsp;</a>
        <a class="text-primary" href="#"> <i class="fa fa-phone"></i> 254 711 056444 | 254 732 156 444 </a>
      </p>
      <p class="social-list">
        <a href="#" class="text-primary"> Terms of Service </a>
        <a href="#">&nbsp;&nbsp;</a>
        <a href="#" class="text-primary"> Privacy Policy</a>
      </p>
    </div>
  </div>
  <!-- end #footer -->
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'

export default {
  name: 'Footer',
  data() {
    return {
      pageOptions: PageOptions,
      app: process.env.VUE_APP_NAME,
      year: new Date().getFullYear()
    }
  }
}
</script>
<style>
.footerz {
  padding: 3.75rem 0;
  text-align: center;
  color: rgba(255, 255, 255, .5);
  font-size: .8125rem;
  -webkit-box-shadow: inset 0 100px 80px -80px rgb(0 0 0 / 70%);
  box-shadow: inset 0 100px 80px -80px rgb(0 0 0 / 70%);
  background: rgba(57, 48, 45, 100);
}

.footerz .footer-brand {
  font-size: 1.53125rem;
  color: #fff;
  font-weight: 300;
  margin-bottom: 1.875rem;
}
</style>

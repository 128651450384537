<template>
  <div id="about" class="container-fluid px-5 blue-wrap mb-5 pt-2 pb-2">
    <h2 class="content-title mt-5">About</h2>
    <div class="row">
      <div class="col-lg-6">
        <div class="about text-center">
          <h3 class="mb-3">Automation of bid bonds / Tender securities</h3>
          <p class="extra-large">
            As CoOperative, we believe that great relationships are built on each other’s success. We believe in empowering business to dream bigger and pursue harder to achieve their goals by carefully crafting solutions that gear their business for growth.
          </p>
          <p class="extra-large">
            In this regard, we have developed an automated Trade Solution that allows customers to request and get Trade Security documents digitally and in real-time via our website. The first of such documents that will be availed on the digital platform is the Bid Bond/Tender Security document.
          </p>
          <p class="extra-large">
            CoOperative has identified convenience, pricing and need for collateral as barriers to participating in government procurement for SME’s and special groups (e.g. women, youth and people with disabilities). Our solution addresses all the above challenges by simplifying the application process, improving accessibility, creating affordability and convenience in acquiring such instruments.
          </p>
          <p class="extra-large">
            For further clarification and or feedback, please contact us.
          </p>
        </div>
      </div>
      <div class="col-lg-6">
          <img src="/login-bg.jpg" class="img-fluid">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About'
}
</script>

<template>
  <div id="quote" class="container mt-5 mb-5 pt-2 pb-2">
    <h2 class="content-title mt-5">Get A Free Quote</h2>
    <div class="row">
      <div class="col-md-6">
        <img class="img-fluid" src="/login-bg.jpg">
      </div>
      <div class="col-md-6">
        <p class="extra-large">
          Get to know your price in seconds, answer a few questions and you are good to go! No irrelevant questions, no
          paper work and no long phone calls!
        </p>
        <form
            v-if="step == 1"
            @submit.prevent="validateForm"
        >
          <div v-html="$error.handle(error)" />
          <br>
          <div class="form-group">
            <vue-numeric
                v-model="quote.tenure"
                v-validate="'required'"
                class="form-control"
                name="tenure_duration"
                placeholder="Tender Period"
            />
            <div
                v-if="errors.has('tenure_duration')"
                class="help-block with-errors"
            >
              {{ errors.first('tenure_duration') }}
            </div>
          </div>

          <div class="form-group">
            <v-select
                v-model="selected_counter_party"
                v-validate="'required'"
                :options="counter_parties"
                class="bg-white"
                label="name"
                name="counterparty"
                placeholder="Select Counter Party"
            />
            <div
                v-if="errors.has('counterparty')"
                class="help-block with-errors"
            >
              {{ errors.first('counterparty') }}
            </div>
          </div>
          <div class="form-group">
            <vue-numeric
                v-model="quote.amount"
                v-validate="'required'"
                class="form-control"
                name="amount"
                placeholder="Bid Amount (KSHS)"
            />
            <div
                v-if="errors.has('amount')"
                class="help-block with-errors"
            >
              {{ errors.first('amount') }}
            </div>
          </div>
          <div class="form-group">
            <input
                v-model="quote.email"
                v-validate="'required|email'"
                autocomplete="email"
                class="form-control"
                minlength="5"
                name="email"
                placeholder="Email Address"
                type="email"
            >
            <div
                v-if="errors.has('email')"
                class="help-block with-errors"
            >
              {{ errors.first('email') }}
            </div>
          </div>
          <div class="form-group">
            <input
                v-model="quote.phone"
                v-validate="'required|min:10|max:15'"
                autocomplete="tel-national"
                class="form-control"
                name="phone"
                placeholder="Phone"
                type="text"
            >
            <div
                v-if="errors.has('phone')"
                class="help-block with-errors"
            >
              {{ errors.first('phone') }}
            </div>
          </div>
          <div class="checkbox ">
            <label>
              <input
                  v-model="terms"
                  v-validate="'required'"
                  checked="checked"
                  name="terms"
                  type="checkbox"
                  value="1"
              >
              <a
                  href="#"
                  @click.prevent="show_terms=true"
              > &nbsp;I HAVE READ AND UNDERSTOOD THE TERMS & CONDITIONS.</a></label>
          </div>
          <div
              v-if="errors.has('terms')"
              class="help-block with-errors"
          >
            {{ errors.first('terms') }}
          </div>
          <div class="form-group">
            <button
                class="btn btn-secondary"
                type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>

    <template v-if="step == 2">
      <div class="sent">
        <h4>Success!</h4>
        <p>The quotation of KES {{ $number.format(quote.amount) }} is:</p>
        <table class="table">
          <tbody>
          <tr>
            <td>Bidbond charge</td>
            <td>{{ $number.format(price.bid_bond_charge) }}</td>
          </tr>
          <tr>
            <td>Excise duty</td>
            <td>{{ $number.format(price.excise_duty) }}</td>
          </tr>
          <tr>
            <td>Indemnity charge</td>
            <td>{{ $number.format(price.indemnity_cost) }}</td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td><strong>KES {{ $number.format(price.total) }}</strong></td>
          </tr>
          </tbody>
        </table>
        <div style="text-align: center; margin-top: 30px;">
          <a
              class="btn btn-primary"
              href="#"
              @click.prevent="sendMail"
          ><i class="fa fa-envelope" /> SEND
            QUOTE TO MAIL</a>
          <br>
          <p style="margin: 20px 0;">
            OR
          </p>
          <a
              class="btn btn-primary"
              href="#"
              @click.prevent="generateBid"
          >GENERATE BIDBOND</a>
        </div>
      </div>
    </template>

    <template v-if="step == 3">
      <div class="sent">
        <h4>Success!</h4>
        <p style="margin: 20px 0;">
          Your quote has been generated and sent to {{ quote.email }}
        </p>
        <router-link
            :to="{ name: 'login' }"
            class="btn btn-primary"
        >
          GENERATE BIDBOND
        </router-link>
      </div>
    </template>

    <modal
        :show-modal="show_terms"
        @close="show_terms=false"
    >
      <template v-slot:header>
        <h5>Terms and Conditions</h5>
      </template>
      <div>
        <terms />
      </div>
      <hr>
      <div class="form-group">
        <a
            class="btn btn-primary"
            href="#"
            @click.prevent="show_terms = false; terms=1"
        >Accept Terms and
          Conditions</a>
        <a
            class="btn btn-danger pull-right"
            href="#"
            @click.prevent="show_terms = false"
        >Cancel</a>
      </div>
    </modal>
  </div>
</template>

<script>
//#TODO: update
import Terms from "@/components/guest/Terms.vue";

export default {
  name: 'Quote',
  components: {
    Terms
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      quote: {
        tenure: '',
        amount: '',
        counterparty: '',
        email: '',
        phone: '',
        country: 'kenya'
      },
      terms: '',
      step: 1,
      price: {},
      show_terms: false,
      error: '',
      selected_counter_party: ''
    }
  },
  computed: {
    counter_parties() {
      return this.$store.getters.getCounterParties;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (!this.counter_parties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.request();
        }
      });
    },
    request() {
      if (this.selected_counter_party.id) {
        this.quote.counterparty = this.selected_counter_party.id;

        this.$axios.post('/api/v1/quote', this.quote).then(response => {
          this.price = response.data;
          this.step = 2;
          this.error = '';
        }).catch(error => {
          this.error = error.response;
        });
      }
    },

    sendMail() {
      this.$axios.post('/api/v1/send-quote', this.quote).then(response => {
        this.price = response.data;
        this.step = 3;
      }).catch(error => {
        this.error = error.response;
      });
    },
    generateBid: function () {
      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: 'Login to generate a bidbond',
        showConfirmButton: false,
        timer: 1500
      });

      if (this.$router.currentRoute.name !== 'login') {
        this.$router.replace({name: 'login'});
      }
    }
  }
}
</script>



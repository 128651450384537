<template>
  <div id="page-container" class="bg-white">
    <div id="home" :style="{'height': windowHeight+'px'}" class="has-bg home">
      <div class="content-bg">
      </div>
      <div class="container home-content mt-5">
        <div id="hero">
          <h3>GENERATE BID BONDS FAST &amp; EASY</h3>
          <a class="btn btn-theme btn-yellow" href="/register">Register</a>
          <a class="btn btn-theme btn-outline-white color-cocoa-brown" @click.prevent="postScroll('quote')">Get
            Quote</a><br />
        </div>
      </div>
    </div>
    <about />
    <quote />
    <validate-bid-bond />
   <Footer/>
    <transition name="fade">
      <div id="pagetop"  v-show="scY > 300" @click="toTop">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
             stroke="#4a5568"
             stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
          <path d="M18 15l-6-6-6 6"/>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
import About from "@/views/About";
import Quote from "@/views/Quote";
import ValidateBidBond from "@/views/ValidateBidBond";
import Footer from "@/components/guest/Footer.vue";

export default {
  name: 'Home',
  mixins:[helper],
  components: {
    About,
    Quote,
    ValidateBidBond,
    Footer
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      scTimer: 0,
      scY: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
  computed: {
    windowHeight() {
      return window.innerHeight;
    },
  }
}
</script>
<style lang="scss">
.has-bg .content-bg {
  background-size: 100% 100% !important;
}
#pagetop{
right: 0px;
bottom: 0px;
position: fixed;
background: #05513c;
cursor: pointer;
}
#hero h3 {
color: #05513c;
font-weight: bolder;
}
.home .home-content p {
font-size: 1.2rem;
}
.content-title:after {
background: #05513c !important;
}
#pagetop:hover {
background: #f9e200 !important;
}
p.extra-large{
font-size: 1.2rem;
}
.has-bg .content-bg {
background-position: 0% !important;
}
.has-bg {
padding-bottom: 3.75rem;
overflow: hidden;
position: relative;

.content-bg {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
background-image: url('/login-bg.jpg');

&:before {
  content: '';
  background: url('/login-bg.jpg');
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
}
}
.home .home-content {
z-index: 1020;
position: absolute;
top: 50%;
left: 0;
right: 0;
color: rgba(255, 255, 255, .5);
margin-top: -7.5rem;
padding: 0 .9375rem;
text-align: center;

.btn+.btn {
margin-left: .9375rem;
}
.btn.btn-theme {
padding: .9375rem 1.875rem;
font-weight: 600;
-webkit-border-radius: 4px;
border-radius: 4px;
font-size: .875rem;
}

h1 {
font-size: 4rem;
font-weight: 600;
}

h3 {
font-size: 2rem;
}

p {
margin-bottom: 3.75rem;
}

h1, h3 {
color: #fff;
margin: 0 0 1.875rem;
font-weight: 300;
}
}
</style>


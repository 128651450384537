<template>
  <div id="validate-bidbond" class="container-fluid bg-light-grey mt-5 pt-2 pb-2">
    <h2 class="content-title mt-5">Validate Bid Bond</h2>
    <h3 class="content-desc font-weight-light">
      Do you need to verify the authenticity of the bid bonds submitted for you tender from the platform?<br>
      <small>Worry not! Simply enter the bid bond reference in the search box and perform the search.</small>
    </h3>
    <!-- begin row -->
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-lg-6 form-col offset-3" >
        <form class="form-horizontal" @submit.prevent="validateForm()">
          <div class="form-group row">
            <label class="col-form-label col-lg-3 text-lg-right">Reference Number <span
                class="text-primary">*</span></label>
            <div class="col-lg-9">
              <input
                  v-model="reference"
                  v-validate="'required|min:7|alpha_num'"
                  class="form-control"
                  name="reference"
                  placeholder="MD...."
                  type="text"
              >
            </div>
          </div>
          <div v-if="errors.has('reference')"
               class="text-danger"
          >
            {{ errors.first('reference') }}
          </div>
          <div class="form-group row mb-5">
            <label class="col-form-label col-lg-3 text-lg-right"></label>
            <div class="col-lg-9 text-left">
              <button
                  v-if="loading"
                  class="btn btn-warning btn-block"
                  type="button"
              >
                <i class="fa fa-spin fa-spinner" /> Loading ...
              </button>
              <button
                  v-else
                  class="btn btn-theme btn-primary btn-block"
                  type="submit"
              >
                <i class="fa fa-search" /> Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- end col-6 -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
export default {
  name: 'ValidateBidBond',
  data() {
    return {
      reference: '',
      loading: false,
    }
  },
  methods: {
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.search();
        }
      });
    },
    search() {
      this.loading = true;
      this.$axios.get(`/api/bidbonds/${this.reference}/byReference`, {
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        const file = new Blob(
            [response.data],
            {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }).catch(() => {
        this.$toastr.e("Failed to find Bidbond!");
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
